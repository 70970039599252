<!DOCTYPE html>
<html lang="fr">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Questionnaire Fun - Mon Site/Appli</title>
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/5.1.3/css/bootstrap.min.css">
</head>

<body>
    <div class="container mt-5">
        <h1 class="text-center">Questionnaire Fun - Mon Site/Appli</h1>
        <p class="text-center">Bienvenue dans notre questionnaire fun et interactif chez ZeroInvestissement.com !</p>

        <form>
            <!-- Section 1 et 2 sur la même ligne -->
            <div class="row">
                <!-- Section 1: L’idée qui va tout changer -->
                <div class="col-md-6">
                    <h2 class="my-4">1. L’idée qui va tout changer</h2>
                    <div class="mb-3">
                        <label for="nom-projet" class="form-label">Nom de votre projet</label>
                        <textarea class="form-control" id="nom-projet" rows="2"></textarea>
                    </div>
                    <div class="mb-3">
                        <label for="pitch-alien" class="form-label">En une phrase, décrivez votre idée comme si vous la
                            pitchiez à un alien</label>
                        <textarea class="form-control" id="pitch-alien" rows="2"></textarea>
                    </div>
                    <div class="mb-3">
                        <label for="animal-projet" class="form-label">Si votre projet était un animal, lequel serait-il
                            et pourquoi ?</label>
                        <textarea class="form-control" id="animal-projet" rows="2"></textarea>
                    </div>
                    <div class="mb-3">
                        <label for="probleme-resolu" class="form-label">Quel est le problème majeur que votre idée va
                            résoudre ?</label>
                        <textarea class="form-control" id="probleme-resolu" rows="3"></textarea>
                    </div>
                    <div class="mb-3">
                        <label for="fonctionnalite" class="form-label">Quelle est la fonctionnalité qui rendra votre
                            site ou appli indispensable ?</label>

                        <input class="me-1" type="checkbox" id="moins-1000" name="audience" value="moins-1000">
                        <label for="moins-1000">Vente en ligne</label><br>
                        <input class="me-1" type="checkbox" id="entre-1000-10000" name="audience"
                            value="entre-1000-10000">
                        <label for="entre-1000-10000">Réservation</label><br>
                        <input class="me-1" type="checkbox" id="plus-10000" name="audience" value="plus-10000">
                        <label for="plus-10000">Service innovant</label><br>
                        <input class="me-1" type="checkbox" id="plus-10000" name="audience" value="plus-10000">
                        <label for="plus-10000">Autre (expliquez-nous !)</label>
                    </div>
                </div>

                <!-- Section 2: Pourquoi cette idée est-elle LA bonne ? -->
                <div class="col-md-6">
                    <h2 class="my-4">2. Pourquoi cette idée est-elle la bonne ?</h2>
                    <div class="mb-3">
                        <label for="origine-idee" class="form-label">Comment vous est venue cette idée ?</label>
                        <textarea class="form-control" id="origine-idee" rows="2"></textarea>
                    </div>
                    <div class="mb-3">
                        <label for="idee-superieure" class="form-label">En une phrase, pourquoi pensez-vous que votre
                            idée est meilleure que ce qui existe déjà ?</label>
                        <textarea class="form-control" id="idee-superieure" rows="2"></textarea>
                    </div>
                    <div class="mb-3">
                        <label for="excitation-projet" class="form-label">Qu’est-ce qui vous rend super excité à l’idée
                            de lancer ce projet ?</label>
                        <textarea class="form-control" id="excitation-projet" rows="2"></textarea>
                    </div>
                    <div class="mb-3">
                        <label for="plan-b" class="form-label">Quel est votre plan B si tout ne se passe pas comme prévu
                            ?</label>
                        <textarea class="form-control" id="plan-b" rows="2"></textarea>
                    </div>
                </div>
            </div>

            <!-- Section 3 -->
            <div class="row">

                <div class="col-md-6">
                    <h2 class="my-4">3. Votre vision du futur : la rentabilité</h2>
                    <div class="mb-3">
                        <label for="monetisation" class="form-label">Comment votre projet va-t-il rapporter des sous
                            ?</label>
                        <textarea class="form-control" id="monetisation" rows="2"></textarea>
                    </div>
                    <div class="mb-3">
                        <label>Combien de personnes pensez-vous que votre projet touchera dans sa première année
                            ?</label><br>
                        <input class="me-1" type="checkbox" id="moins-1000" name="audience" value="moins-1000">
                        <label for="moins-1000">Moins de 1 000</label><br>
                        <input class="me-1" type="checkbox" id="entre-1000-10000" name="audience"
                            value="entre-1000-10000">
                        <label for="entre-1000-10000">Entre 1 000 et 10 000</label><br>
                        <input class="me-1" type="checkbox" id="plus-10000" name="audience" value="plus-10000">
                        <label for="plus-10000">Plus de 10 000</label>
                    </div>

                    <div class="mb-3">
                        <label>D’ici 2 ans, votre projet aura généré…</label><br>
                        <input class="me-1" type="checkbox" id="10000" name="revenu" value="10000">
                        <label for="10000">10 000 €</label><br>
                        <input class="me-1" type="checkbox" id="50000" name="revenu" value="50000">
                        <label for="50000">50 000 €</label><br>
                        <input class="me-1" type="checkbox" id="plus-100000" name="revenu" value="plus-100000">
                        <label for="plus-100000">Plus de 100 000 €</label><br>
                        <input class="me-1" type="checkbox" id="explosion" name="revenu" value="explosion">
                        <label for="explosion">Ça va exploser tous les compteurs, vous allez voir !</label>
                    </div>
                </div>

                <div class="col-md-6">
                    <!-- Section 4 -->
                    <h2 class="my-4">4. Public cible et stratégie marketing</h2>
                    <div class="mb-3">
                        <label for="client-ideal" class="form-label">Votre cible idéale : Décrivez votre client idéal
                            comme si c’était votre meilleur ami</label>
                        <textarea class="form-control" id="client-ideal" rows="2"></textarea>
                    </div>
                    <div class="mb-3">
                        <label for="strategie" class="form-label">Avez-vous déjà pensé à une stratégie pour atteindre
                            ces personnes ? Si oui, laquelle ?</label>
                        <textarea class="form-control" id="strategie" rows="2"></textarea>
                    </div>
                    <div class="mb-3">
                        <label for="reseaux-sociaux" class="form-label">Si vous deviez choisir une plateforme de réseaux
                            sociaux pour promouvoir votre projet, laquelle serait-elle ?</label>
                        <div class="mb-3">
                            <label class="form-label">Quels réseaux sociaux utilisez-vous ? (Vous pouvez en sélectionner
                                plusieurs)</label><br>
                            <input class="me-1" type="checkbox" id="instagram" name="reseaux-sociaux" value="instagram">
                            <label for="instagram">Instagram</label><br>
                            <input class="me-1" type="checkbox" id="tiktok" name="reseaux-sociaux" value="tiktok">
                            <label for="tiktok">TikTok</label><br>
                            <input class="me-1" type="checkbox" id="linkedin" name="reseaux-sociaux" value="linkedin">
                            <label for="linkedin">LinkedIn</label><br>
                            <input class="me-1" type="checkbox" id="facebook" name="reseaux-sociaux" value="facebook">
                            <label for="facebook">Facebook</label><br>
                            <div class="mt-2 d-flex align-items-center" id="autre-reseaux">
                                <input class="me-1" type="checkbox" id="autre" name="reseaux-sociaux" value="autre">
                                <label for="autre" class="me-2">Autre</label>
                                <input type="text" class="form-control" placeholder="">
                            </div>

                        </div>

                    </div>
                </div>

            </div>




            <div class="row">

                <div class="col-md-6">
                    <!-- Section 5 -->
                    <h2 class="my-4">5. Rentabilité et Engagement</h2>
                    <div class="mb-3">
                        <label for="engagement" class="form-label">Êtes-vous prêt à vous engager à 100% pour faire
                            grandir ce
                            projet ?</label><br>
                        <input class="me-1" type="checkbox" id="engagement-total" name="engagement" value="total">
                        <label for="engagement-total">Oui, je suis à fond !</label><br>
                        <input class="me-1" type="checkbox" id="engagement-partiel" name="engagement" value="partiel">
                        <label for="engagement-partiel">Oui, mais je continue d'autres projets en parallèle.</label>
                    </div>
                    <div class="mb-3">
                        <label for="disponibilites" class="form-label">Quelles sont vos disponibilités ?</label><br>
                        <input class="me-1" type="checkbox" id="temps-plein" name="disponibilites" value="temps-plein">
                        <label for="temps-plein">Temps plein</label><br>
                        <input class="me-1" type="checkbox" id="mi-temps" name="disponibilites" value="mi-temps">
                        <label for="mi-temps">Temps partiel</label><br>
                        <input class="me-1" type="checkbox" id="temps-libre" name="disponibilites" value="temps-libre">
                        <label for="temps-libre"> Pendant mes soirées/week-ends.</label>
                    </div>

                    <div class="mb-3">
                        <label for="disponibilites" class="form-label">Avez-vous des fonds, investisseurs ou partenaires prêts à vous soutenir ?</label><br>
                        <input class="me-1" type="checkbox" id="temps-plein" name="disponibilites" value="temps-plein">
                        <label for="temps-plein">Oui, j’ai déjà des investisseurs</label><br>
                        <input class="me-1" type="checkbox" id="mi-temps" name="disponibilites" value="mi-temps">
                        <label for="mi-temps"> Pas encore, mais je suis prêt à en chercher</label><br>
                        <input class="me-1" type="checkbox" id="temps-libre" name="disponibilites" value="temps-libre">
                        <label for="temps-libre"> Non, je compte sur ZeroInvestissement.com !</label>
                    </div>
                    <div class="mb-3">
                        <label for="ressources" class="form-label">Si vous deviez vendre votre idée à un investisseur en une phrase, que diriez-vous ?</label>
                        <textarea class="form-control" id="ressources" rows="2"></textarea>
                    </div>
                   
                </div>
                <div class="col-md-6">
                    <!-- SECTION 6 -->
                    <h2 class="my-4">6. Laissez votre créativité parler : le design</h2>
                    <div class="mb-3">
                        <label for="couleur-dominante" class="form-label">Si votre site/appli avait une couleur
                            dominante, ce serait…</label><br>
                        <input class="me-1" type="checkbox" id="rouge" name="couleur-dominante" value="rouge">
                        <label for="rouge">Rouge (énergique, passionné)</label><br>
                        <input class="me-1" type="checkbox" id="bleu" name="couleur-dominante" value="bleu">
                        <label for="bleu">Bleu (calme, fiable)</label><br>
                        <input class="me-1" type="checkbox" id="vert" name="couleur-dominante" value="vert">
                        <label for="vert">Vert (naturel, apaisant)</label><br>
                        <input class="me-1" type="checkbox" id="noir" name="couleur-dominante" value="noir">
                        <label for="noir">Noir (élégant, sophistiqué)</label><br>
                        <input class="me-1" type="checkbox" id="autre" name="couleur-dominante" value="autre">
                        <label for="autre">Autre (surprenez-nous !) :</label>
                        <textarea class="form-control" id="style-design" rows="2"></textarea>
                    </div>
                    <div class="mb-3">
                        <label for="style-design" class="form-label">Quel est le style de design qui vous attire le plus
                            ?</label>
                        <textarea class="form-control" id="style-design" rows="2"></textarea>
                    </div>
                    <div class="mb-3">
                        <label for="exemples-inspirants" class="form-label">Avez-vous des exemples de sites/applis qui
                            vous inspirent ? Si oui, lesquels ?</label>
                        <textarea class="form-control" id="exemples-inspirants" rows="2"></textarea>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Avez-vous déjà des éléments graphiques comme un logo ou une charte
                            graphique ?</label><br>
                        <input class="me-1" type="checkbox" id="elements-prets" name="elements-graphique" value="prets">
                        <label for="elements-prets">Oui, tout est prêt !</label><br>
                        <input class="me-1" type="checkbox" id="besoin-aide" name="elements-graphique" value="aide">
                        <label for="besoin-aide">Non, j’aurais besoin d’aide sur ce point.</label>
                    </div>
                </div>
            </div>


            <div class="row">

                <div class="col-md-6">
                    <!-- Section 7: Technique et plateformes -->
                    <h2 class="my-4">7. Technique et plateformes</h2>
                    <div class="mb-3">
                        <label class="form-label">Pour quel support souhaitez-vous développer votre projet ?</label><br>
                        <input class="me-1" type="checkbox" id="site-web" name="support" value="site-web">
                        <label for="site-web">Site web</label><br>
                        <input class="me-1" type="checkbox" id="application-mobile" name="support"
                            value="application-mobile">
                        <label for="application-mobile">Application mobile</label><br>
                        <input class="me-1" type="checkbox" id="les-deux" name="support" value="les-deux">
                        <label for="les-deux">Les deux !</label>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Sur quelle(s) plateforme(s) souhaitez-vous que votre appli soit
                            disponible
                            ?</label><br>
                        <input class="me-1" type="checkbox" id="android" name="plateforme" value="android">
                        <label for="android">Android</label><br>
                        <input class="me-1" type="checkbox" id="ios" name="plateforme" value="ios">
                        <label for="ios">iOS</label><br>
                        <input class="me-1" type="checkbox" id="les-deux" name="plateforme" value="les-deux">
                        <label for="les-deux">Les deux</label>
                    </div>
                    <div class="mb-3">
                        <label for="exigences" class="form-label">Avez-vous des exigences spécifiques en termes de
                            technologie
                            ou d’hébergement ?</label>
                        <textarea class="form-control" id="exigences" rows="2"></textarea>
                    </div>

                </div>
                <div class="col-md-6">
                    <!-- Section 8: Un peu plus sur vous -->
                    <h2 class="my-4">8. Un peu plus sur vous (on aime bien en savoir plus)</h2>
                    <div class="mb-3">
                        <label for="superpouvoir" class="form-label">Quel est votre superpouvoir en tant qu’entrepreneur
                            ?</label>
                        <textarea class="form-control" id="superpouvoir" rows="2"></textarea>
                    </div>
                    <div class="mb-3">
                        <label for="loisirs" class="form-label">Que faites-vous quand vous ne travaillez pas sur votre
                            projet
                            ?</label>
                        <textarea class="form-control" id="loisirs" rows="2"></textarea>
                    </div>
                    <div class="mb-3">
                        <label for="dejeuner-entrepreneur" class="form-label">Si vous pouviez déjeuner avec un
                            entrepreneur
                            célèbre, qui serait-ce et pourquoi ?</label>
                        <textarea class="form-control" id="dejeuner-entrepreneur" rows="2"></textarea>
                    </div>
                    <div class="mb-3">
                        <label for="action-post-success" class="form-label">Quelle serait la première chose que vous
                            ferez une
                            fois que votre projet sera un succès mondial ?</label>
                        <textarea class="form-control" id="action-post-success" rows="2"></textarea>
                    </div>

                </div>
            </div>




            <p class="text-center mt-4">Merci d’avoir joué le jeu ! Nous allons analyser votre projet avec attention et
                vous recevrez une réponse sous 10 jours ouvrés. On adore les idées créatives, rentables et qui ont du
                punch ! Si votre projet est sélectionné, préparez-vous à embarquer dans une aventure extraordinaire avec
                ZeroInvestissement.com.</p>

            <p class="text-center">Pourquoi ce questionnaire ? Parce que nous voulons être sûrs de bien comprendre votre
                vision, vos motivations et surtout le potentiel de votre idée. Et comme on adore la créativité, vos
                réponses funs nous donneront un vrai aperçu de qui vous êtes, et pourquoi votre projet mérite d’être
                propulsé par notre équipe. Plus on vous connaît, mieux on pourra vous accompagner vers le succès !</p>

            <button type="submit" class="btn btn-primary">Envoyer</button>
        </form>
    </div>


</body>

</html>