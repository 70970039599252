import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AuthenticationService } from '../../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../../core/services/authfake.service';

import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { User } from 'src/app/shared/models/user';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: UntypedFormGroup;
  submitted = false;
  error = '';
  returnUrl: string;

  // set the currenr year
  year: number = new Date().getFullYear();

  // tslint:disable-next-line: max-line-length
  constructor(private formBuilder: UntypedFormBuilder,
     private route: ActivatedRoute, private router: Router,
      public authenticationService: AuthenticationService, 
      public authFackservice: AuthfakeauthenticationService,
      private messageService:MessageService,
      private authService:AuthService,
      private localStorage:LocalStorageService,
      private ngxService : NgxUiLoaderService

    ) { }

  ngOnInit() {
    document.body.removeAttribute('data-layout');
    document.body.classList.add('auth-body-bg');

    this.loginForm = this.formBuilder.group({
      email: ['admin@themesdesign.in', [Validators.required, Validators.email]],
      password: ['123456', [Validators.required]],
    });

    // reset login status
    // this.authenticationService.logout();
    // get return url from route parameters or default to '/'
    // tslint:disable-next-line: no-string-literal
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  /**
   * Form submit
   */
  item : User = new User()

  onSubmit() {
    
    if (!this.item.canSend()) {
      console.log("aa");
 
      this.messageService.add({ severity: 'secondary', summary: 'zero-investissement', detail: this.item.getErrorMessage() });
      return;
    }
    
    if (this.item) {
      this.ngxService.start();
      this.authService.login(this.item).subscribe(
        (response: any) => {
          this.messageService.add({ severity: 'success', summary: 'zero-investissement', detail: response.message });
          this.ngxService.stop();
          this.router.navigate(['/page/dashboard']);
          this.localStorage.saveUsers(response)
        },
        (error) => {
          console.log("une erreur est survenue", error);
          this.messageService.add({ severity: 'secondary', summary: 'zero-investissement', detail: error });
          this.ngxService.stop();

        }
      ).add(() => {
      });
    }
  }//end onSubmit


}
