<div class="container-fluid">

    <h4 id="upper">Incubateur / Accélérateur Zéro</h4>
  
    <div class="container py-4">
          <!-- Tabs Section -->
          <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link active" href="#">Entreprise</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">Finances</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">Concurrence</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">Produits / Services</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">Marketing</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">Ressources humaines</a>
            </li>
        </ul>
      <form class="mt-4">
        <div class="row">
          <div class="col-md-4 mb-3">
            <label for="nom-entreprise" class="form-label">Nom de l'entreprise</label>
            <input type="text" class="form-control" id="nom-entreprise" placeholder="Nom de l'entreprise">
          </div>
          <div class="col-md-4 mb-3">
            <label for="taille-entreprise" class="form-label">Taille de l'entreprise</label>
            <select class="form-select" id="taille-entreprise">
              <option selected>ex: grande entreprise</option>
              <option value="1">Petite entreprise</option>
              <option value="2">Moyenne entreprise</option>
              <option value="3">Grande entreprise</option>
            </select>
          </div>
        </div>
      
        <div class="row">
          <div class="col-md-4 mb-3">
            <label for="secteur-activite" class="form-label">Secteur d'activité</label>
            <input type="text" class="form-control" id="secteur-activite" placeholder="ex: Tech">
          </div>
          <div class="col-md-4 mb-3">
            <label for="date-creation" class="form-label">Date de création</label>
            <input type="date" class="form-control" id="date-creation">
          </div>
        </div>
      
        <div class="row">
          <div class="col-md-4 mb-3">
            <label for="budget-estime" class="form-label">Budget estimé</label>
            <input type="number" class="form-control" id="budget-estime">
          </div>
          <div class="col-md-4 mb-3">
            <label for="description-projet" class="form-label">Description du projet</label>
            <textarea class="form-control" id="description-projet" rows="2"></textarea>
          </div>
        </div>
      
        <div class="text-left mt-3">
          <button type="submit" class="btn btn-primary">Soumettre le projet</button>
        </div>
      </form>
    </div>
  
  </div>
  