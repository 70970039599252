<!DOCTYPE html>
<html lang="fr">

<body>
    <div class="container mt-5">
        <h1 class="mb-4 text-center">Questionnaire fun Ma franchise sans investissement</h1>
        <p class="text-center">Bienvenue dans notre questionnaire hyper fun et essentiel pour faire de votre entreprise
            une franchise à succès sans dépenser un centime ! Si vous avez une entreprise qui cartonne déjà et que vous
            voulez la voir grandir et conquérir de nouveaux territoires, vous êtes au bon endroit. Chez
            ZeroInvestissement.com, on adore les entreprises qui rêvent en grand, et on est là pour vous aider à
            franchiser sans stress !</p>
        <p class="text-center">Alors, prêt à transformer votre entreprise en la prochaine grande franchise ? Répondez à
            ces questions et voyons comment on peut faire décoller tout ça !</p>

        <form>
            <!-- Section 1 -->
            <div class="form-section">
                <h2 class="questionnaire-header">1. Votre Entreprise Actuelle : Montrez-nous ce que vous avez !</h2>
                <div class="row mb-3">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="nom-entreprise">Nom de votre entreprise (et pourquoi ce nom ?) :</label>
                            <input type="text" class="form-control" id="nom-entreprise"
                                placeholder="Nom de l'entreprise">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="personnage-film">Si votre entreprise était un personnage de film, lequel
                                serait-ce et pourquoi ?</label>
                            <input type="text" class="form-control" id="personnage-film"
                                placeholder="Personnage de film">
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="description-activite">Décrivez votre activité en une phrase :</label>
                            <input type="text" class="form-control" id="description-activite" placeholder="Description">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Depuis combien de temps votre entreprise existe-t-elle ?</label>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" name="temps-existence"
                                    id="moins-d-un-an" value="moins-d-un-an">
                                <label class="form-check-label" for="moins-d-un-an">Moins d’un an</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" name="temps-existence" id="1-a-3-ans"
                                    value="1-a-3-ans">
                                <label class="form-check-label" for="1-a-3-ans">1 à 3 ans</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" name="temps-existence"
                                    id="plus-de-3-ans" value="plus-de-3-ans">
                                <label class="form-check-label" for="plus-de-3-ans">Plus de 3 ans</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="secteur-activite">Quel est votre secteur d’activité ?</label>
                            <input type="text" class="form-control" id="secteur-activite"
                                placeholder="Secteur d'activité">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="produits-services">Quels sont les 3 produits/services phares de votre entreprise
                                ?</label>
                            <input type="text" class="form-control" id="produits-services"
                                placeholder="Produits/Services">
                        </div>
                    </div>
                </div>
            </div>

            <!-- Section 2 -->
            <div class="form-section">
                <h2 class="questionnaire-header">2. Le Succès : Racontez-nous votre aventure !</h2>
                <div class="row mb-3">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="reussite">Votre entreprise cartonne, mais quelle est la plus grande réussite
                                dont vous êtes fier ?</label>
                            <input type="text" class="form-control" id="reussite"
                                placeholder="Votre plus grande réussite">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Combien de clients avez-vous servi jusqu’à aujourd’hui ?</label>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" name="clients-servis" id="moins-de-100"
                                    value="moins-de-100">
                                <label class="form-check-label" for="moins-de-100">Moins de 100</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" name="clients-servis" id="100-a-500"
                                    value="100-a-500">
                                <label class="form-check-label" for="100-a-500">100 à 500</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" name="clients-servis" id="plus-de-500"
                                    value="plus-de-500">
                                <label class="form-check-label" for="plus-de-500">Plus de 500</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Quel est votre chiffre d’affaires actuel ?</label>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" name="chiffre-affaires"
                                    id="moins-de-100000" value="moins-de-100000">
                                <label class="form-check-label" for="moins-de-100000">Moins de 100 000 €</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" name="chiffre-affaires"
                                    id="100000-a-500000" value="100000-a-500000">
                                <label class="form-check-label" for="100000-a-500000">Entre 100 000 € et 500 000
                                    €</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" name="chiffre-affaires"
                                    id="plus-de-500000" value="plus-de-500000">
                                <label class="form-check-label" for="plus-de-500000">Plus de 500 000 €</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Sur une échelle de 1 à 10, comment évaluez-vous la scalabilité de votre modèle
                                ?</label>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" name="scalabilite" id="1" value="1">
                                <label class="form-check-label" for="1">1 : Pas encore prêt à se déployer</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" name="scalabilite" id="5" value="5">
                                <label class="form-check-label" for="5">5 : Ça pourrait marcher ailleurs</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" name="scalabilite" id="10" value="10">
                                <label class="form-check-label" for="10">10 : Mon modèle est prêt à conquérir le monde
                                    !</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Section 3 -->
            <div class="form-section">
                <h2 class="questionnaire-header">3. Le Grand Saut : Pourquoi franchiser votre entreprise ?</h2>
                <div class="row mb-3">
                    <div class="col-md-6">
                        <!-- Question 1 -->
                        <div class="form-group">
                            <label for="raison-transformer">Pourquoi souhaitez-vous transformer votre entreprise en
                                franchise ?</label>
                            <div>
                                <div class="form-check">
                                    <input class="form-check-input me-1" type="checkbox" id="visibility"
                                        value="visibility">
                                    <label class="form-check-label" for="visibility">Accroître ma visibilité</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input me-1" type="checkbox" id="revenue" value="revenue">
                                    <label class="form-check-label" for="revenue">Augmenter mon chiffre
                                        d’affaires</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input me-1" type="checkbox" id="share-success"
                                        value="share-success">
                                    <label class="form-check-label" for="share-success">Partager mon succès avec
                                        d’autres entrepreneurs</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="other" value="other">
                                    <label class="form-check-label" for="other">Autre (précisez) : </label>
                                    <input type="text" class="form-control" id="other-reason"
                                        placeholder="Précisez ici...">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <!-- Question 2 -->
                        <div class="form-group">
                            <label for="excitement">Qu’est-ce qui vous excite le plus à l’idée de voir votre marque à
                                plusieurs endroits ?</label>
                            <textarea class="form-control" id="excitement" rows="3"
                                placeholder="Votre réponse ici..."></textarea>
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-md-6">
                        <!-- Question 3 -->
                        <div class="form-group">
                            <label for="existing-locations">Votre entreprise est-elle déjà implantée dans plusieurs
                                villes/régions ?</label>
                            <div>
                                <div class="form-check">
                                    <input class="form-check-input me-1" type="radio" name="locations" id="yes"
                                        value="yes">
                                    <label class="form-check-label" for="yes">Oui</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input me-1" type="radio" name="locations" id="no"
                                        value="no">
                                    <label class="form-check-label" for="no">Non, mais c’est le rêve !</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <!-- Question 4 -->
                        <div class="form-group">
                            <label for="franchise-count">Avez-vous déjà une idée du nombre de franchises que vous
                                souhaitez lancer dans les 3 prochaines années ?</label>
                            <div>
                                <div class="form-check">
                                    <input class="form-check-input me-1" type="radio" name="franchise-count" id="1-5"
                                        value="1-5">
                                    <label class="form-check-label" for="1-5">1 à 5 franchises</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input me-1" type="radio" name="franchise-count" id="6-10"
                                        value="6-10">
                                    <label class="form-check-label" for="6-10">5 à 10 franchises</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input me-1" type="radio" name="franchise-count" id="6-10"
                                        value="6-10">
                                    <label class="form-check-label" for="6-10"> Plus de 10 franchises, je vise grand !
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- Section 4 -->
            <div class="form-section">
                <h2 class="questionnaire-header">4. Les Détails qui Comptent : Scalabilité et Potentiel</h2>

                <div class="row mb-3">
                    <!-- Question 1 -->
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="scalability">Comment votre entreprise peut-elle être reproduite facilement dans
                                d’autres villes ou pays ?</label>
                            <textarea class="form-control" id="scalability" rows="3"
                                placeholder="Expliquez ici..."></textarea>
                        </div>
                    </div>

                    <!-- Question 2 -->
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="resources">Quelles sont les ressources clés nécessaires pour lancer une
                                franchise de votre entreprise ?</label>
                            <textarea class="form-control" id="resources" rows="3"
                                placeholder="Personnel, matériel spécifique, formation..."></textarea>
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <!-- Question 3 -->
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="growth">Votre secteur est-il en pleine croissance ?</label>
                            <textarea class="form-control" id="growth" rows="3"
                                placeholder="Expliquez ici pourquoi c’est le bon moment de franchiser..."></textarea>
                        </div>
                    </div>

                    <!-- Question 4 -->
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="market-studies">Avez-vous déjà envisagé des données géographiques ou des études
                                de marché pour vos futures implantations ?</label>
                            <textarea class="form-control" id="market-studies" rows="3"
                                placeholder="Ex : population cible, concurrence locale..."></textarea>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Section 5 -->
            <div class="form-section">
                <h2 class="questionnaire-header">5. Valeurs et Vision (C’est là qu’on connecte !)</h2>

                <div class="row mb-3">
                    <!-- Question 1 -->
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="values">Quelles sont les valeurs clés de votre entreprise ?</label>
                            <textarea class="form-control" id="values" rows="3"
                                placeholder="Innovation, respect, qualité, service client..."></textarea>
                        </div>
                    </div>

                    <!-- Question 2 -->
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="motto">Si votre entreprise était une devise inspirante, ce serait…</label>
                            <textarea class="form-control" id="motto" rows="3"
                                placeholder="Votre devise ici..."></textarea>
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <!-- Question 3 -->
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="essential-values">En quoi ces valeurs seront-elles essentielles pour vos futurs
                                franchisés ?</label>
                            <textarea class="form-control" id="essential-values" rows="3"
                                placeholder="Pourquoi est-ce important qu’ils partagent ces valeurs ?"></textarea>
                        </div>
                    </div>

                    <!-- Question 4 -->
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="franchisee-traits">Que recherchez-vous chez un futur franchisé ?</label>
                            <textarea class="form-control" id="franchisee-traits" rows="3"
                                placeholder="Engagement, passion, vision d’ensemble…"></textarea>
                        </div>
                    </div>
                </div>
            </div>


            <!-- Section 6 -->
            <div class="form-section">
                <h2 class="questionnaire-header">6. Data et Stratégie (La partie sérieuse mais essentielle)</h2>

                <div class="row mb-3">
                    <!-- Question 1 -->
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Connaissez-vous les principaux concurrents dans les zones où vous souhaitez vous
                                implanter ?</label>
                            <div>
                                <input class="me-1" type="checkbox" id="concurrents-oui" name="concurrents" value="oui">
                                <label for="concurrents-oui">Oui, je les ai bien identifiés</label>
                            </div>
                            <div>
                                <input class="me-1" type="checkbox" id="concurrents-non" name="concurrents" value="non">
                                <label for="concurrents-non">Non, pas encore</label>
                            </div>
                        </div>
                    </div>

                    <!-- Question 2 -->
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Disposez-vous déjà de données d’analyse pour identifier les meilleures zones
                                d’implantation ?</label>
                            <textarea class="form-control" id="data-analysis" rows="3"
                                placeholder="Population, revenu moyen, etc."></textarea>
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <!-- Question 3 -->
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Si non, aimeriez-vous que ZeroInvestissement.com vous aide à analyser ces données
                                pour trouver les meilleurs emplacements ?</label>
                            <div>
                                <input class="me-1" type="checkbox" id="aide-oui" name="aide" value="oui">
                                <label for="aide-oui">Oui, j’ai besoin d’aide !</label>
                            </div>
                            <div>
                                <input class="me-1" type="checkbox" id="aide-non" name="aide" value="non">
                                <label for="aide-non">Non, j’ai déjà tout ce qu’il me faut</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Section 7 -->
            <div class="form-section">
                <h2 class="questionnaire-header">7. La Gestion et l’Accompagnement : Allons-nous bien bosser ensemble ?
                </h2>

                <div class="row mb-3">
                    <!-- Question 1 -->
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Quel rôle souhaitez-vous jouer dans le développement de votre franchise ?</label>
                            <div>
                                <input class="me-1" type="checkbox" id="role-gerer" name="role" value="gerer">
                                <label for="role-gerer">Gérer tous les aspects avec nos conseils</label>
                            </div>
                            <div>
                                <input class="me-1" type="checkbox" id="role-deleguer" name="role" value="deleguer">
                                <label for="role-deleguer">Déléguer une partie à nos experts, tout en gardant un œil sur
                                    les opérations</label>
                            </div>
                            <div>
                                <input class="me-1" type="checkbox" id="role-zero" name="role" value="zero">
                                <label for="role-zero">Laisser ZeroInvestissement.com gérer entièrement le développement
                                    et l’accompagnement</label>
                            </div>
                        </div>
                    </div>

                    <!-- Question 2 -->
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Comment imaginez-vous la formation de vos futurs franchisés ?</label>
                            <textarea class="form-control" id="formation-vision" rows="3"
                                placeholder="Structure, contenu, durée… partagez-nous votre vision"></textarea>
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <!-- Question 3 -->
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Quels sont les outils essentiels que vous pensez devoir fournir à vos franchisés
                                ?</label>
                            <textarea class="form-control" id="outils-franchise" rows="3"
                                placeholder="Ex : Manuels d’opération, outils marketing, formations"></textarea>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-section">
                <h2 class="questionnaire-header">8. L’aventure commence ! (On s’amuse un peu)</h2>

                <div class="row mb-3">
                    <!-- Question 1 -->
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Si votre entreprise était une chanson, laquelle serait-ce et pourquoi ?</label>
                            <textarea class="form-control" id="chanson-entreprise" rows="3"
                                placeholder="Répondez ici"></textarea>
                        </div>
                    </div>

                    <!-- Question 2 -->
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Imaginez que votre entreprise devienne la franchise la plus célèbre du pays, quelle
                                serait la première chose que vous feriez ?</label>
                            <textarea class="form-control" id="celebrite-entreprise" rows="3"
                                placeholder="Répondez ici"></textarea>
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <!-- Question 3 -->
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Si vous deviez déjeuner avec une autre grande marque franchisée pour obtenir des
                                conseils, laquelle choisiriez-vous et pourquoi ?</label>
                            <textarea class="form-control" id="dejeuner-franchise" rows="3"
                                placeholder="Répondez ici"></textarea>
                        </div>
                    </div>
                </div>

                <!-- Conclusion du questionnaire -->
                <div class="conclusion-section text-center">
                    <h3>Merci d’avoir partagé votre vision !</h3>
                    <p>Notre équipe d’experts analysera votre projet avec attention et vous recevrez une réponse sous 15
                        jours ouvrés. Si votre modèle est validé, ZeroInvestissement.com vous accompagnera pour faire de
                        votre entreprise la prochaine grande franchise, sans investissement de votre part. On est prêts
                        à vous propulser vers le succès, et vous ?</p>

                    <h4>Pourquoi ce questionnaire ?</h4>
                    <p>Nous voulons nous assurer que votre entreprise est prête à franchir un cap, que votre modèle est
                        duplicable, et que vous partagez nos valeurs. Plus on en sait sur vous et votre vision, mieux on
                        pourra vous accompagner pour transformer votre réussite locale en succès national… voire mondial
                        !</p>
                </div>
            </div>


            <button type="submit" class="btn btn-primary">Envoyer</button>
        </form>
    </div>

    <!-- Bootstrap JS -->
</body>

</html>