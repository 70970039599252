import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-soumission-projet',
  templateUrl: './soumission-projet.component.html',
  styleUrl: './soumission-projet.component.scss'
})
export class SoumissionProjetComponent {

  constructor(private router :Router)
  {}
  

  goToSoumission(){

    this.router.navigate(['/page/soumission']);
  }
}
