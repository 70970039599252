<section class="h-100 gradient-form" style="background-color: #eee;">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-xl-10">
        <div class="card rounded-3 text-black">
          <div class="row g-0">
            <!-- Partie gauche avec image et texte -->
            <div id="gauche" class="col-lg-4">
              <div class="card-body p-md-5 mx-md-4">
                <div class="text-center">
                  <h4 class="mt-1 mb-5 pb-1">Zéro Investissement, transformez vos idées en réalités.</h4>
                  <img src="../../../../assets/images/zero.png" style="width: 185px;" alt="logo">
                </div>
              </div>
            </div>

            <!-- Partie droite avec le formulaire d'inscription -->
            <div class="col-lg-8 gradient-custom-2 d-flex align-items-center justify-content-center">
              <div class="text-white px-3 py-4 p-md-5 mx-md-4 w-100">
                <form>
                  <p id="black" class="text-center mb-4">Veuillez vous inscrire à votre compte</p>
                  <h3 class="text-center"> Rejoignez notre communauté en  remplissant les informations ci-dessous.</h3>
                  <!-- Nom et Prénom -->
                  <div class="row">
                    <div class="col-md-6 form-group mb-4">
                      <label id="black" class="form-label" for="firstName">Nom</label>
                      <input type="text" id="firstName" class="form-control" placeholder="Nom" />
                    </div>
                    <div class="col-md-6 form-group mb-4">
                      <label id="black" class="form-label" for="lastName">Prénom</label>
                      <input type="text" id="lastName" class="form-control" placeholder="Prénom" />
                    </div>
                  </div>

                  <!-- Email et Téléphone -->
                  <div class="row">
                    <div class="col-md-6 form-group mb-4">
                      <label id="black" class="form-label" for="email">Email</label>
                      <input type="email" id="email" class="form-control" placeholder="Email" />
                    </div>
                    <div class="col-md-6 form-group mb-4">
                      <label id="black" class="form-label" for="telephone">Téléphone</label>
                      <input type="tel" id="telephone" class="form-control" placeholder="Téléphone" />
                    </div>
                  </div>

                  <!-- Mot de passe et Confirmation de mot de passe -->
                  <div class="row">
                    <div class="col-md-6 form-group mb-4">
                      <label id="black" class="form-label" for="password">Mot de passe</label>
                      <input type="password" id="password" class="form-control" placeholder="Mot de passe" />
                    </div>
                    <div class="col-md-6 form-group mb-4">
                      <label id="black" class="form-label" for="confirmPassword">Confirmation du mot de passe</label>
                      <input type="password" id="confirmPassword" class="form-control" placeholder="Confirmer le mot de passe" />
                    </div>
                  </div>

                  <!-- Boutons -->
                  <div class="text-center pt-1 mb-5 pb-1">
                    <button style="background-color: #6a00b8; width: 200px;" class="btn btn-block fa-lg mb-3" type="button">Suivant<i class="pi pi-chevron-right" style="font-size: 0.9rem"></i>
                    </button>
                  </div>

                  <div class="d-flex align-items-center justify-content-center pb-4">
                    <p class="mb-0 me-2" style="color: black;">Vous avez déjà un compte ?   <a  href="" routerLink="/">Se connecter </a></p>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
