import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 2,
        label: 'Accueil',
        icon: 'ri-dashboard-line',
        badge: {
            variant: 'success',
            text: '',
        },
        link: 'dashboard'
    },
 
    {
        id: 5,
        label: 'Projet soumis',
        icon: 'ri-store-2-line',
        link: '/page/projet-soumis',

        // subItems: [
        //     {
        //         id: 6,
        //         label: 'Liste des utilisateurs',
        //         parentId: 5
        //     },
          
         
        // ]
    },
    {
        id: 13,
        label: 'Messagerie',
        icon: 'ri-mail-send-line',
        subItems: [
            {
                id: 14,
                label: 'Liste des contributions',
                link: '/page/contribution',
                parentId: 14
            },
           
        ]
    },
    
    {
        id: 23,
        label: 'Services',
        icon: 'ri-profile-line',
        isTitle: true,

    },

 
    {
        id: 18,
        label: 'Paramètre',
        icon: 'ri-settings-line',
       
    },  
    {
        id: 19,
        label: 'Mon compte',
        icon: 'ri-user-line',
       
    },
    {
        id: 20,
        label: 'Déconnexion',
        icon: 'ri-logout-box-r-line',
       
    },
  
  
   
  
];
