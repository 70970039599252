<section class="h-100 gradient-form" style="background-color: #eee;">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-xl-10">
        <div class="card rounded-3 text-black">
          <div class="row g-0">
            <div id="gauche" class="col-lg-6">
              <div class="card-body p-md-5 mx-md-4">
                <div class="text-center">
                  <h4 class="mt-1 mb-5 pb-1">Zéro Investissement, transformez vos idées en réalités.</h4>
                  <img src="../../../../assets/images/zero.png" style="width: 185px;" alt="logo">
                </div>
              </div>
            </div>
            <div class="col-lg-6 gradient-custom-2 d-flex align-items-center justify-content-center">
              <div class="text-white px-3 py-4 p-md-5 mx-md-4">
                <form>
                
                  <p id="black" class="text-center">Veuillez vous connecter à votre compte</p>

                  <div class="form-outline mb-4">
                    <label id="black" class="form-label" for="form2Example11">Login</label>
                    <input type="email" id="form2Example11" class="form-control" placeholder="Phone number or email address" />
                  </div>

                  <div class="form-outline mb-4">
                    <label id="black" class="form-label" for="form2Example22">Mot de passe</label>
                    <input type="password" id="form2Example22" class="form-control" />
                  </div>

                  <div class="text-center pt-1 mb-5 pb-1">
                    <button style="background-color: #6a00b8;" routerLink="/page/dashboard" class="btn btn-block fa-lg mb-3" type="button">Connexion</button>
                   &nbsp; <a class="text-muted" href="#!">Mot de passe oublié ?</a>
                  </div>

                  <div class="d-flex align-items-center justify-content-center pb-4">
                    <p class="mb-0 me-2 " style="color: black;">Vous n'avez pas de compte ? <a href="" routerLink="signup">S'inscrire</a> </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
