import { Component } from '@angular/core';

@Component({
  selector: 'app-incubateur',
  templateUrl: './incubateur.component.html',
  styleUrl: './incubateur.component.scss'
})
export class IncubateurComponent {

}
