<!DOCTYPE html>
<html lang="fr">

<body>
    <div class="container mt-5">
        <h1 class="mb-4 text-center">III- Questionnaire 1 POLE Marketplace Dépôt</h1>
        <p class="text-center">Bienvenue dans l’espace Marketplace Dépôt de ZeroInvestissement.com ! Vous avez une idée
            brillante ou un business prêt à décoller que vous souhaitez vendre sur notre marketplace ? Parfait ! Nous
            voulons en savoir plus sur votre concept, son potentiel, et pourquoi
            vous pensez qu’il mérite d’être mis en avant. Soyez créatif, amusez-vous, et faites-nous rêver !</p>
        <h2>1. L’Essence de Votre Business</h2>

        <form>
           <div class="form-group">
                <label for="nom-projet">Nom de votre projet ou entreprise (et pourquoi ce nom vous semble parfait
                    ?)</label>
                <input type="text" class="form-control" id="nom-projet" placeholder="Entrez le nom de votre projet">
            </div>

            <div class="form-group">
                <label for="elevator-pitch">En une phrase, vendez-nous votre business comme si vous parliez à quelqu'un
                    dans l'ascenseur :</label>
                <textarea class="form-control" id="elevator-pitch" rows="3" placeholder="Votre pitch"></textarea>
            </div>

            <div class="form-group">
                <label for="plat-business">Si votre projet était un plat, lequel serait-il et pourquoi ?</label>
                <textarea class="form-control" id="plat-business" rows="3" placeholder="Répondez ici"></textarea>
            </div>

            <div class="form-group">
                <label>Quelle est la mission principale de votre business ?</label><br>
                <input type="checkbox"> Vendre des produits uniques<br>
                <input type="checkbox"> Offrir des services innovants<br>
                <input type="checkbox"> Changer le monde (ou au moins votre secteur !)<br>
                <input type="checkbox"> Autre : <input type="text" placeholder="Expliquez ici">
            </div>

            <div class="form-group">
                <label for="secteur">Dans quel secteur d’activité opère votre business et pourquoi l’avez-vous choisi
                    ?</label>
                <textarea class="form-control" id="secteur" rows="3" placeholder="Secteur d’activité"></textarea>
            </div>

            <!-- Section 2: Potentiel et Scalabilité -->
            <h2>2. Potentiel et Scalabilité (Rêvons en grand !)</h2>

            <div class="form-group">
                <label for="machine-a-succes">Qu’est-ce qui fait de votre business une machine à succès ?</label>
                <textarea class="form-control" id="machine-a-succes" rows="3" placeholder="Répondez ici"></textarea>
            </div>

            <div class="form-group">
                <label>Sur une échelle de 1 à 10, à quel point pensez-vous que ce business est duplicable ailleurs
                    ?</label><br>
                <input type="radio" name="duplicable" value="1"> 1 : Très difficile<br>
                <input type="radio" name="duplicable" value="5"> 5 : Ça pourrait marcher<br>
                <input type="radio" name="duplicable" value="10"> 10 : Facile à déployer n’importe où<br>
            </div>

            <div class="form-group">
                <label>Combien de clients avez-vous actuellement ou espérez-vous toucher ?</label><br>
                <input type="radio" name="clients" value="moins-500"> Moins de 500<br>
                <input type="radio" name="clients" value="500-2000"> Entre 500 et 2000<br>
                <input type="radio" name="clients" value="plus-2000"> Plus de 2000<br>
            </div>

            <div class="form-group">
                <label>Quel chiffre d’affaires annuel ce projet a-t-il déjà généré (ou peut-il générer) ?</label><br>
                <input type="radio" name="chiffre-affaires" value="moins-50000"> Moins de 50 000 €<br>
                <input type="radio" name="chiffre-affaires" value="50000-200000"> Entre 50 000 € et 200 000 €<br>
                <input type="radio" name="chiffre-affaires" value="plus-200000"> Plus de 200 000 €<br>
            </div>

            <div class="form-group">
                <label for="licorne-magique">Si votre business était une licorne, en quoi serait-elle magique ?</label>
                <textarea class="form-control" id="licorne-magique" rows="3" placeholder="Votre réponse"></textarea>
            </div>

            <!-- Section 3: Opérations et Technique -->
            <h2>3. Opérations et Technique : Parlons Pratique !</h2>

            <div class="form-group">
                <label for="fonctionnalites">Quelles sont les 3 fonctionnalités clés de votre business qui le rendent
                    irrésistible ?</label>
                <textarea class="form-control" id="fonctionnalites" rows="3" placeholder="Détaillez ici"></textarea>
            </div>

            <div class="form-group">
                <label>Faut-il des compétences spécifiques pour gérer ce business, ou est-il prêt à être pris en main
                    par n’importe qui ?</label><br>
                <input type="radio" name="competences" value="oui"> Oui, des compétences techniques sont nécessaires<br>
                <input type="radio" name="competences" value="non"> Non, il est simple à gérer<br>
            </div>

            <div class="form-group">
                <label>Avez-vous déjà des outils ou technologies en place (site web, logiciels, plateformes)
                    ?</label><br>
                <input type="radio" name="outils" value="oui"> Oui, tout est en place<br>
                <input type="radio" name="outils" value="non"> Non, ça reste à développer<br>
            </div>

            <div class="form-group">
                <label>Avez-vous une équipe qui fonctionne déjà ou tout est prêt à être repris par le futur propriétaire
                    ?</label><br>
                <input type="radio" name="equipe" value="oui"> Équipe en place<br>
                <input type="radio" name="equipe" value="non"> Business prêt à être repris en solo<br>
            </div>

            <!-- Section 4: Proposition de Valeur -->
            <h2>4. Proposition de Valeur : Pourquoi votre projet est un must-have ?</h2>

            <div class="form-group">
                <label for="valeur-unique">Qu’est-ce qui rend votre business unique et irrésistible pour un acheteur
                    potentiel ?</label>
                <textarea class="form-control" id="valeur-unique" rows="3" placeholder="Expliquez ici"></textarea>
            </div>

            <div class="form-group">
                <label>Quel est le prix que vous avez en tête pour vendre ce projet ?</label><br>
                <input type="radio" name="prix" value="moins-10000"> Moins de 10 000 €<br>
                <input type="radio" name="prix" value="10000-50000"> Entre 10 000 € et 50 000 €<br>
                <input type="radio" name="prix" value="plus-50000"> Plus de 50 000 €<br>
            </div>

            <div class="form-group">
                <label for="achat-inclus">Que comprend exactement l’achat ?</label>
                <textarea class="form-control" id="achat-inclus" rows="3"
                    placeholder="Ex : Clients existants, stock, manuel d’opération..."></textarea>
            </div>

            <div class="form-group">
                <label for="slogan">Si votre business devait apparaître dans une pub télé, quel serait le slogan
                    ?</label>
                <input type="text" class="form-control" id="slogan" placeholder="Votre slogan">
            </div>

            <!-- Section 5: Support et Suivi -->
            <h2>5. Support et Suivi : Vous accompagnez l’acheteur ?</h2>

            <div class="form-group">
                <label>Seriez-vous prêt à accompagner l’acheteur avec une formation initiale ou des conseils
                    ?</label><br>
                <input type="radio" name="accompagnement" value="oui"> Oui, je suis ouvert à l'accompagnement
                initial<br>
                <input type="radio" name="accompagnement" value="non"> Non, je préfère tout céder d’un coup<br>
            </div>

            <div class="form-group">
                <label>Avez-vous déjà des partenaires, fournisseurs, ou collaborateurs avec qui l'acheteur pourrait
                    travailler ?</label><br>
                <input type="radio" name="partenaires" value="oui"> Oui, ils sont prêts à collaborer<br>
                <input type="radio" name="partenaires" value="non"> Non, pas de partenaires à ce jour<br>
            </div>

            <button type="submit" class="btn btn-primary">Envoyer</button>
        </form>
    </div>

    <!-- Bootstrap JS -->
</body>

</html>