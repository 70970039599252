import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { IncubateurComponent } from './projet-soumis/incubateur/incubateur.component';
import { SiteComponent } from './projet-soumis/site/site.component';
import { SoumissionProjetComponent } from './projet-soumis/soumission-projet/soumission-projet.component';
import { SoumissionComponent } from './projet-soumis/soumission/soumission.component';
import { FranchiseComponent } from './projet-soumis/franchise/franchise.component';
import { MarketplaceComponent } from './projet-soumis/marketplace/marketplace.component';
;

const routes: Routes = [
    { path: 'dashboard', component: DashboardComponent }, 
    { path: 'projet-soumis', component: SoumissionProjetComponent }, 
    { path: 'soumission', component: SoumissionComponent }, 
    { path: 'incubateur', component: IncubateurComponent }, 
    { path: 'fun-site', component: SiteComponent }, 
    { path: 'franchise', component: FranchiseComponent }, 
    { path: 'marketplace', component: MarketplaceComponent }, 
    { path: 'pages', loadChildren: () => import('./utility/utility.module').then(m => m.UtilityModule) },
    { path: 'ui', loadChildren: () => import('./ui/ui.module').then(m => m.UIModule) },
    { path: 'icons', loadChildren: () => import('./icons/icons.module').then(m => m.IconsModule) },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule { }
