import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UiModule } from '../shared/ui/ui.module';
import { WidgetModule } from '../shared/widget/widget.module';

import { PagesRoutingModule } from './pages-routing.module';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgbDropdownModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DndModule } from 'ngx-drag-drop';
import { NgPipesModule } from 'ngx-pipes';
import { SimplebarAngularModule } from 'simplebar-angular';

import { DashboardComponent } from './dashboard/dashboard.component';

import { RouterModule } from '@angular/router';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PaginatorModule } from 'primeng/paginator';
import { ToastModule } from 'primeng/toast';
import { IconsModule } from './icons/icons.module';
import { IncubateurComponent } from './projet-soumis/incubateur/incubateur.component';
import { SiteComponent } from './projet-soumis/site/site.component';
import { SoumissionProjetComponent } from './projet-soumis/soumission-projet/soumission-projet.component';
import { SoumissionComponent } from './projet-soumis/soumission/soumission.component';
import { UIModule } from './ui/ui.module';
import { FranchiseComponent } from './projet-soumis/franchise/franchise.component';
import { MarketplaceComponent } from './projet-soumis/marketplace/marketplace.component';



@NgModule({
  declarations: [DashboardComponent,IncubateurComponent,SoumissionComponent,SoumissionProjetComponent,SiteComponent,FranchiseComponent,MarketplaceComponent],
  imports: [ 
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    UiModule,
    UIModule,
    NgPipesModule,
    NgbNavModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgApexchartsModule,
    DndModule,
    FullCalendarModule,
    IconsModule,
    LeafletModule,
    WidgetModule,
    SimplebarAngularModule,
    PaginatorModule,
    ConfirmDialogModule,
    ToastModule,
    NgxUiLoaderModule,
    RouterModule
  ],
  providers: [
  ]
})
export class PagesModule { }
