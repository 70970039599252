<div class="container-fluid">

  <h4 id="upper">Projets soumis</h4>
  <div class="row">
    <div class="container my-5">
        <div class="row g-4">
          <!-- Derniers projets -->
          <div class="col-md-4">
            <div class="project-list">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <h5>Derniers projet</h5>
                <a href="#" class="text-purple">Voir tout</a>
              </div>
              <ul class="list-unstyled">
                <li class="d-flex justify-content-between align-items-center mb-3">
                  <div class="d-flex align-items-center">
                    <div class="rounded-circle bg-violet text-white d-flex justify-content-center align-items-center" style="width: 40px; height: 40px;">
                      PM
                    </div>
                    <div class="ms-3">
                      <strong>Plateforme de E-Commerce</strong><br>
                      <small>Jul, 25</small>
                    </div>
                  </div>
                  <span class="text-muted">En attente</span>
                </li>
                <li class="d-flex justify-content-between align-items-center mb-3">
                  <div class="d-flex align-items-center">
                    <div class="rounded-circle bg-violet text-white d-flex justify-content-center align-items-center" style="width: 40px; height: 40px;">
                      MS
                    </div>
                    <div class="ms-3">
                      <strong>App Mobile de Gestion des Rendez-vous</strong><br>
                      <small>Jul, 28</small>
                    </div>
                  </div>
                  <span class="text-danger">Rejeté</span>
                </li>
                <li class="d-flex justify-content-between align-items-center mb-3">
                  <div class="d-flex align-items-center">
                    <div class="rounded-circle bg-violet text-white d-flex justify-content-center align-items-center" style="width: 40px; height: 40px;">
                      AR
                    </div>
                    <div class="ms-3">
                      <strong>Outil de Collaboration en Ligne</strong><br>
                      <small>Aug, 01</small>
                    </div>
                  </div>
                  <span class="text-success">Validé</span>
                </li>
                <li class="d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center">
                    <div class="rounded-circle bg-violet text-white d-flex justify-content-center align-items-center" style="width: 40px; height: 40px;">
                      PB  
                    </div>
                    <div class="ms-3">
                      <strong>Plateforme de Formation en Ligne</strong><br>
                      <small>Jul, 28</small>
                    </div>
                  </div>
                  <span class="text-success">Validé</span>
                </li>
              </ul>
              
            </div>
          </div>
      
          <!-- Soumettez votre projet -->
          <div class="col-md-4">
            <div class="submit-project">
              <div class="d-flex justify-content-between">
                <img src="../../../../assets/images/student.png" height="190px" alt="Submit Project Image">

              </div>
              <h5 class="my-3">Soumettez votre projet</h5>
              <div class="d-flex justify-content-between text-start">
                <p class="">Vous avez une idée innovante ? Transformez-la en réalité avec le soutien de Zero Investissement.</p>

              </div>
              <button (click)="goToSoumission()" class="btn btn-purple">Soumettre un projet</button>
            </div>
          </div>
      
          <!-- Statistiques -->
          <div class="col-md-4">
            <div class="stats">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <h5 style="color: white;">Statistiques</h5>
                <a href="#" class="text-white">Voir tout</a>
              </div>
              <p>Nombre projets soumis <span class="float-end">13</span></p>
              <div class="progress mb-3">
                <div class="progress-bar" role="progressbar" style="width: 80%"></div>
              </div>
      
              <p>Nombre projet rejetés <span class="float-end">5</span></p>
              <div class="progress mb-3">
                <div class="progress-bar" role="progressbar" style="width: 40%"></div>
              </div>
      
              <p>En attente de validation <span class="float-end">2</span></p>
              <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 20%"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
