import { Component } from '@angular/core';

@Component({
  selector: 'app-soumission',
  templateUrl: './soumission.component.html',
  styleUrl: './soumission.component.scss'
})
export class SoumissionComponent {

}
