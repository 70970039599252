<div class="container-fluid">
  <app-pagetitle title="Tableaux de bord" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="container mt-4">
    <div class="card shadow-lg p-4">
      <div class="row g-4">
        <!-- Texte et boutons -->
        <div class="col-md-6 d-flex flex-column justify-content-center">
          <h2 class="card-title" id="font"> <strong id="font">Bienvenue chez Zéro Investissement !</strong></h2>
          <p class="card-text mb-3" id="fontp">
            Découvrez comment nous vous accompagnons à <strong> <b>chaque étape</b></strong>  de votre <strong> <b> parcours entrepreneurial</b></strong>. <br>
             Lancez votre entreprise avec l'aide de notre plateforme. <br> Cette vidéo vous guidera à travers
              les services et fonctionnalités que nous offrons pour soutenir la croissance de votre entreprise. Suivez les étapes pour maximiser votre succès.
          </p>
          <div class="mt-3 d-flex align-items-center">
            <button class="btn btn-primary me-2 d-flex align-items-center" style="background-color: #30384a; color: white;">
              Passer cette étape
              <i class="pi pi-angle-right ms-2" style="font-size: 1.6rem"></i>
            </button>
            
            <button class="btn btn-outline-secondary d-flex align-items-center" style="background-color: #6a00b8; color: white;">
              Plus de détails
              <i class="pi pi-globe ms-2" style="font-size: 1.6rem"></i>
            </button>
          </div>
          
        </div>
        
        <!-- Image -->
        <div class="col-md-6 d-flex justify-content-center align-items-center">
          <img src="../../../assets/images/rename.png" class="img-fluid rounded" alt="Coming Soon" style="max-height: 400px;">
        </div>
      </div>
    </div>
  </div>
  
</div>
  